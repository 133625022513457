"use client";

import { ServerErrorIcon } from "@/components/icons";

const ServerError = () => {
	return (
		<section className="flex min-h-screen w-full flex-col items-center justify-center bg-[#FFFBEB] px-4 py-6 sm:px-12 sm:py-12 mlg:h-screen mlg:flex-row mlg:py-4">
			<div className="order-2 flex h-full w-full items-center justify-center mlg:order-1 mlg:w-1/2">
				<div className="flex w-full max-w-xs flex-col items-center justify-center text-center md:max-w-xl mlg:items-start mlg:text-start">
					<h1 className="mb-4 text-2xl font-bold leading-snug text-[#5F2E2E] sm:mb-8 sm:text-4xl xl:text-6xl mlg:text-5xl">
						We&apos;re making your experience better
					</h1>
					<p className="mb-6 text-sm text-[#5F2E2E] sm:text-lg md:text-xl lg:text-3xl mlg:text-2xl">
						Please try again in a moment
					</p>
					<button
						className="rounded-md bg-[#893333] px-5 py-2.5 text-base text-white"
						onClick={() => window.location.reload()}
					>
						Refresh
					</button>
				</div>
			</div>
			<div className="order-1 mx-auto h-full sm:w-3/4 md:w-1/2 mlg:order-2">
				<ServerErrorIcon className="mx-auto h-full w-full max-w-xl object-contain" />
			</div>
		</section>
	);
};

export default ServerError;
